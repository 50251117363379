<nav class="navbar navbar-light">
    <form class="form-inline">
        <button class="btn btn-success col-xs-3 col-sm-3 col-md-3 col-lg-3 col-lg-3" type="button" style="width: 200px" [routerLink]="['']">Accueil</button>
        <button class="btn btn-sm btn-primary col-xs-3 col-sm-3 col-md-3 col-lg-3 col-lg-3" type="button" style="width: 200px" [routerLink]="['accueil']">let's ARTISTS
        </button>
        <button class="btn btn-sm btn-primary col-xs-3 col-sm-3 col-md-3 col-lg-3 col-lg-3" type="button" style="width: 200px" [routerLink]="['chicago']">CHICAGO's Crime Data
        </button>
        <button class="btn btn-sm btn-primary col-xs-3 col-sm-3 col-md-3 col-lg-3 col-lg-3" type="button" style="width: 200px" [routerLink]="['parking']">
          Parking's
        </button>
        <button class="btn btn-sm btn-primary col-xs-3 col-sm-3 col-md-3 col-lg-3 col-lg-3" type="button" style="width: 200px" [routerLink]="['ml-page']">
          Mentions Légales
        </button>
    </form>
</nav>
