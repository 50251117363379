<div style="text-align:center">
  <h1>
    Parking de Toulouse
  </h1>
</div>

<!-- Déclaration du conteneur de la carte avec l'id "frugalmap" -->
<div style="padding: 100px;">
  <div id="frugalmap"></div>
</div>
