import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Idata } from "./idata";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private localUrl: string = "../assets/data/data.json";

  constructor(private http: HttpClient) {}

  public getData(): Observable<Idata[]> {
    return this.http.get<Idata[]>(this.localUrl);
  }
}
