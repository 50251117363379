<div id="container" style="text-align:center">

    <h3><strong>Carte des emplacements artistiques</strong></h3>
    <p>
        <small class="text-muted">Cliquez sur un lieu pour en afficher les informations</small>
    </p>

    <!-- Déclaration du conteneur de la carte avec l'id "frugalmap" -->
    <div id="frugalmap"></div>

    <h1>
        <button type="button" class="btn btn-info btn-lg" [routerLink]="['/accueil']">Retour à la sélection</button>
    </h1>
</div>
