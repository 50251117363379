import { Component, OnInit, Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";
//Importer le service et son interface:
import { DataService } from '../services/data.service';
import { Idata } from '../services/idata';


@Component({
  selector: 'app-c-les-menus',
  templateUrl: './c-les-menus.component.html',
  styleUrls: ['./c-les-menus.component.scss']
})
export class CLesMenusComponent implements OnInit {
  public dataUne: Idata;
  public tab_installation: Array<Idata> = [];
  public json_select : Idata;
  public carte_id : Array<any> = [];

  constructor(private dataService: DataService, private titleService:Title) {
    this.titleService.setTitle("Accueil Art");
  }

  ngOnInit() {
    this.dataService.getData().subscribe(res => { this.tab_installation = res;
    });
  }

  createCarteId(json : Idata) {
    this.carte_id[0] = json.properties.titre ;
    this.carte_id[1] = json.properties.artiste;
    this.carte_id[2] = json.properties.lieux ;
    this.carte_id[3] = json.properties.photo ;
    this.carte_id[4] = json.properties.description ;
    this.carte_id[5] = json.properties.adresse ;
    this.carte_id[6] = json.properties.code_postal+' '+json.properties.ville ;
    this.carte_id[7] = json.properties.lien ;
  }
  selectData(value : string): void{

    console.log(value);
    //Parcourir le tableau pour récupérer l'indice du json appelé
    console.log(this.tab_installation);
    for (let i =0; i<this.tab_installation.length ; i ++ ){
      if ((value == this.tab_installation[i].properties.artiste) || (value == this.tab_installation[i].properties.ville)){
            this.json_select = this.tab_installation [i];
      }
    }
    //Création de la liste des données propres à l'installation selectionnée
    this.createCarteId(this.json_select);

  }

  deleteData(): void{
    console.log('dans la fonction deleteDate');
    for (let i =0; i<this.carte_id.length ; i ++ ){
      this.carte_id[i]= '';
    }
    console.log(this.carte_id);
  }

}
