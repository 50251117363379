<div class="container-fluid bg" style="">
    <div class="">

        <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="vignette align-self-center">
                <div class="align-middle" id="titre">
                    <span>CHICAGO CRIME DATA</span>
                </div>
                <div id="milieucrime"></div>
                <div id="piedDePage" class="text-center">
                    <div>
                        <a class="btn btn-warning" routerLink="/chicago">Launch</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div id="container"> -->
        <!-- <div class="row"> -->

        <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="vignette align-self-center">
                <div class="align-middle" id="titre">
                    <span>LIEUX ARTISTIQUES</span>
                </div>

                <div id="milieuart"></div>

                <div id="piedDePage" class="text-center">
                    <div>
                        <a class="btn btn-warning" routerLink="/accueil">Launch</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->

        <!-- <div id="container"> -->
        <!-- <div class="row"> -->

        <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="vignette align-self-center">
                <div class="align-middle" id="titre">
                    <span>PARKING TOULOUSE</span>
                </div>

                <div id="milieupark"></div>

                <div id="piedDePage" class="text-center">
                    <div>
                        <a class="btn btn-warning" routerLink="/parking">Launch</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </div>
</div>
