import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from "highcharts";
import { ChicagoService } from "../services/chicago.service";

@Component({
  selector: 'app-chicago-graph',
  templateUrl: './chicago-graph.component.html',
  styleUrls: ['./chicago-graph.component.scss']
})
export class ChicagoGraphComponent implements OnInit {
  annee=2021;
  graph: any;

  tabType: any[] = [];
  tabValeur: any[] = [];
  tabTypeNext: any[] = [];
  tabTypePrev: any[] = [];
  @Input() year  ;

  public options: any = {
    chart: {
      type: 'column'
    },
    xAxis: {
      categories: this.tabType
    },
    title: {
      text: `${this.annee}'s top 5 crime categories`,
    },
    series: [{
      name: `${this.annee}`,
      data: this.tabValeur
    }
  ]
  }

  constructor(private _graph: ChicagoService) { }

  ngOnInit(): void {
    //Highcharts.chart('container', this.options);
  }

  ngOnChanges(changes) {
    if (this.year){
      this.annee=this.year;
      this.tabType=[];
      this.tabValeur=[];
      this._graph.getTop5(this.annee).subscribe(res => {
        res.forEach(element => {

        let y: number = +element.count;
        this.tabType.push(element.primary_type);
        // requete année n-1 => tabvaleurPrev
        this.tabValeur.push(parseInt(element.count));
      });
      //console.log(this.tabValeur);
      //console.log("opt ser name");
      this.options.xAxis.categories=this.tabType;
      this.options.series[0].name=this.annee;
      this.options.series[0].data=this.tabValeur;
      this.options.title.text= `${this.annee}'s top 5 crime categories`;
      this.graph = Highcharts.chart('container', this.options);


    })
  }
  }
}
