<div class="back">
    <div class="container-fluid col-sm-12 col-lg-10 col-lg-offset-1" style="background-color: rgba(178, 192, 192, 0.836)">

        <h1 class="text-center" style="padding:15px"> Bienvenue </h1>

        <div class="col-lg-12">
            <div>

                <p class="text-center">Pour visualiser une installation artistique, sélectionnez une option</p>
                <div class="form-group row col-sm-12 col-md-12 col-lg-12 align-items-center text-center" style="padding:0">
                    <div class="col-form-label col-sm-3 col-md-4 col-lg-4"></div>
                    <div class="col-sm-6 col-md-6 col-lg-6 align-items-center" style="padding-left:0">
                        <label for="city"></label>
                        <!-- <div class="col-lg-12 "> -->
                        <select class="col-lg-10" #city (change)='selectData(city.value)'>
              <option disabled hidden selected>Choisir une ville</option>
              <option *ngFor="let dataUne of tab_installation" [value]="dataUne.properties.ville"> {{
                                    dataUne.properties.ville }}</option>
            </select>
                        <!-- </div> -->
                        <div class="col-lg-10" style="margin-top:10px; font-weight:bold">
                            <p class="text-center">ou</p>
                        </div>
                        <label for="nomArtiste"></label>.
                        <!-- <div class="col-lg-12"> -->
                        <select class="col-lg-10" #nomArtiste (change)='selectData(nomArtiste.value)'>
              <option disabled hidden selected >Choisir un(e) artiste</option>
              <option *ngFor="let dataUne of tab_installation" [value]="dataUne.properties.artiste">{{
                                    dataUne.properties.artiste }}</option>
            </select>
                        <!-- </div> -->

                        <div class="col-form-label col-sm-3 col-md-4 col-lg-4"></div>
                    </div>


                    <div class="col-form-label col-sm-4 col-md-4 col-lg-4"></div>
                    <div class="col-sm-6 col-md-6 col-lg-5 align-items-center" style="padding:0">
                        <div class="text-center">
                            <br> Ou afficher la <a routerLink="/map"><b>carte</b></a> pour géolocaliser l'ensemble des installations.
                        </div>
                        <!-- Resources <a class="card" target="_blank" rel="noopener" href="https://angular.io/tutorial"></a>-->

                        <div id="carte_identite">
                            <button class="btn btn-secondary" type="delete" (click)='deleteData()'> Effacer information
              </button>
                        </div>
                        <div id="carte_identite">
                            <button class="btn btn-success" [routerLink]="['/map']"> Afficher la carte des emplacements artistiques
              </button>
                        </div>
                        <div class="col-form-label col-sm-3 col-md-4 col-lg-4"></div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12" style="padding-right: 5%;padding-left: 10%;">
                    <ul>
                        <li class="text-center">{{ carte_id[0] }}</li>
                        <li class="text-center">{{ carte_id[1] }}</li>
                        <li class="text-center">{{ carte_id[2] }}</li>
                        <li class="text-center"><img src="{{ carte_id[3] }}"></li>
                        <li class="text-justify">{{ carte_id[4] }}</li>
                        <li class="text-center">{{ carte_id[5] }}</li>
                        <li class="text-center">{{ carte_id[6] }}</li>
                        <li class="text-center"><a>{{ carte_id[7] }}</a></li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
