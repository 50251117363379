import { Component, OnInit } from '@angular/core';
import { ChicagoService } from '../services/chicago.service';

@Component({
  selector: 'app-chicago-app',
  templateUrl: './chicago-app.component.html',
  styleUrls: ['./chicago-app.component.scss']
})
export class ChicagoAppComponent implements OnInit {

  donnees: any;
  total : any;
  annees : any;
  coordonnees :any;

  constructor(private serviceChicago: ChicagoService) { }

  ngOnInit(): void {
  }
}
