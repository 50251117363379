import { Component, OnInit } from '@angular/core';
import { ChicagoService } from '../services/chicago.service';

@Component({
  selector: 'app-chicago-selection-date',
  templateUrl: './chicago-selection-date.component.html',
  styleUrls: ['./chicago-selection-date.component.scss']
})
export class ChicagoSelectionDateComponent implements OnInit {

  annees: any;

  public CData: any;
  constructor(private chicagoService: ChicagoService) { }

  ngOnInit(): void {

    this.chicagoService.getAnnees().subscribe(
      (data) => {
        this.annees = data;
      },
      (error) => {
        console.log("erreur");
      },
      () => {}

    )

  }




public recupererDateSaisie(mavariable){
  //console.log(mavariable);
  return mavariable;

}



}
