<div id="composant">
    <div class="row">
        <div class="col-lg-2"></div>
        <div id="header" class="col-lg-8 text-center">
            <span id="titre">CHICAGO CRIME DATA</span>
        </div>
        <div class="col-lg-2"></div>
    </div>

    <div class="row">
        <div class="col-lg-5"></div>

        <div class="col-lg-2">
            <SELECT class="form-control text-center" type="text" #selectionDate>
                <option *ngFor="let date of annees">{{ date.year }}</option>
            </SELECT>
        </div>

        <div class="col-lg-5"></div>
    </div>

</div>

<p #selectitem style="display: none;">{{CData}}</p>
<!-- style="display: none;"
    <h6>FIN COMPOSANT SELECTION DATE</h6>
-->

<app-chicago-tableau [year]="selectionDate.value" (childEvent)="CData=$event"></app-chicago-tableau>
<app-chicago-graph [year]="selectionDate.value"></app-chicago-graph>
<app-chicago-map [year]="selectionDate.value" [item]="selectitem.innerText"></app-chicago-map>
