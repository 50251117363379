import { Injectable, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ChicagoService {

  private url = "https://data.cityofchicago.org/resource/ijzp-q8t2.json";
  private localUrl = "./assets/top5_2020.json";
  private localUrl2 = "./assets/total_2020.json";
  li: any;
  totalYear = [];

  constructor(private http: HttpClient) { }

  public getTop5(year: number): Observable<any> {
    const top5Url = `?$select=distinct(primary_type)AS%20primary_type,count(id)AS%20count&$group=primary_type&$where=year=${year}&$order=count%20DESC&$limit=5`;
    //console.log(this.url + top5Url);
    return this.http.get(this.url + top5Url);
  }

  public getTotal(year: number): Observable<any> {
    const totalUrl = `?$select=count(id)AS%20count&$where=year=${year}`;
    //console.log(this.url + totalUrl);
    return this.http.get(this.url + totalUrl);

  }

  public getAnnees(): Observable<any> {

    const anneesDispo = `?$select=distinct(year)AS%20year&$order=year%20DESC`;
    return this.http.get(this.url + anneesDispo);
  }

  public getCoordonnees(year: number, type: String): Observable<any> {

   const coordURL=`?$select=location&$where=primary_type=%22${type}%22%20and%20year=${year}&$limit=300000`;
   //console.log(this.url + coordURL);
   return this.http.get(this.url + coordURL);
  }


}
