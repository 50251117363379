import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletMarkerClusterModule } from "@asymmetrik/ngx-leaflet-markercluster";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CBarreNavComponent } from './c-barre-nav/c-barre-nav.component';
import { CLesMenusComponent } from './c-les-menus/c-les-menus.component';
import { CMapComponent } from './c-map/c-map.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { MlPageComponent } from './ml-page/ml-page.component';
import { ParkingComponent } from './parking/parking.component';
import { ChicagoAppComponent } from './chicago-app/chicago-app.component';
import { ChicagoGraphComponent } from './chicago-graph/chicago-graph.component';
import { ChicagoMapComponent } from './chicago-map/chicago-map.component';
import { ChicagoSelectionDateComponent } from './chicago-selection-date/chicago-selection-date.component';
import { ChicagoTableauComponent } from './chicago-tableau/chicago-tableau.component';
import { MainPageComponent } from './main-page/main-page.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    AppComponent,
    CBarreNavComponent,
    CLesMenusComponent,
    CMapComponent,
    MentionsLegalesComponent,
    MlPageComponent,
    ParkingComponent,
    ChicagoAppComponent,
    ChicagoGraphComponent,
    ChicagoMapComponent,
    ChicagoSelectionDateComponent,
    ChicagoTableauComponent,
    MainPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LeafletModule,
    LeafletMarkerClusterModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
