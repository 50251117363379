import { Component, Input, OnInit , Output, EventEmitter} from '@angular/core';
import { ChicagoService } from '../services/chicago.service';

@Component({
  selector: 'app-chicago-tableau',
  templateUrl: './chicago-tableau.component.html',
  styleUrls: ['./chicago-tableau.component.scss']
})
export class ChicagoTableauComponent implements OnInit {

  @Input() year :number ;
  @Output() childEvent = new EventEmitter;

  label: any;
  total: any;



  constructor(private chicagoService: ChicagoService) { }

  ngOnInit(): void {

  }


  ngOnChanges(changes) {
    if (this.year){
    this.chicagoService.getTop5(this.year).subscribe(
      (data) => {
        this.label = data;
      },
      (error) => {
        console.log("erreur composant tableau getTop5");
      },
      () => {}

    )

    this.chicagoService.getTotal(this.year).subscribe(
      (data) => {
        this.total = data;
      },
      (error) => {
        console.log("erreur composant tableau getTotal");
      },
      () => {}

    )
  }
}

  addNewValue(value: any) {
    //console.log(value);
    this.childEvent.emit(value);
  }



}
