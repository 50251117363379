<div class="row">
  <h1 style="text-align: center;">Mentions Légales</h1>

  <section *ngIf="tab[0]">

    <div class="col-xs-12 col-sm-4" id="groupe1">
      <h3>Groupe 1</h3>
      <ul>
        <li>{{ tab[2].nom }} {{ tab[2].prenom }}</li>
        <li>{{ tab[1].nom }} {{ tab[1].prenom }}</li>
        <li>{{ tab[0].nom }} {{ tab[0].prenom }}</li>
        <li>{{ tab[3].nom }} {{ tab[3].prenom }}</li>
        <li>{{ tab[4].nom }} {{ tab[4].prenom }}</li>
      </ul>
    </div>

    <div class="col-xs-12 col-sm-4" id="groupe2">
      <h3>Groupe 2</h3>
      <ul>
        <li>{{ tab[7].nom }} {{ tab[7].prenom }}</li>
        <li>{{ tab[9].nom }} {{ tab[9].prenom }}</li>
        <li>{{ tab[5].nom }} {{ tab[5].prenom }}</li>
        <li>{{ tab[11].nom }} {{ tab[11].prenom }}</li>
        <li>{{ tab[14].nom }} {{ tab[14].prenom }}</li>
      </ul>
    </div>

    <div class="col-xs-12 col-sm-4" id="groupe3">
      <h3>Groupe 3</h3>
      <ul>
        <li>{{ tab[10].nom }} {{ tab[10].prenom }}</li>
        <li>{{ tab[12].nom }} {{ tab[12].prenom }}</li>
        <li>{{ tab[6].nom }} {{ tab[6].prenom }}</li>
        <li>{{ tab[8].nom }} {{ tab[8].prenom }}</li>
        <li>{{ tab[13].nom }} {{ tab[13].prenom }}</li>
      </ul>
    </div>
  </section>

</div>
