import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CMapComponent } from './c-map/c-map.component';
import { CLesMenusComponent } from './c-les-menus/c-les-menus.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { MlPageComponent } from './ml-page/ml-page.component';
import { MainPageComponent } from './main-page/main-page.component';
import { ParkingComponent } from './parking/parking.component';
import { ChicagoAppComponent } from './chicago-app/chicago-app.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: MainPageComponent },
  { path: 'map', component: CMapComponent },
  { path: 'accueil', component: CLesMenusComponent },
  { path: 'mentions_legales', component: MentionsLegalesComponent },
  { path: 'ml-page', component: MlPageComponent },
  { path: 'parking', component: ParkingComponent },
  { path: 'chicago', component: ChicagoAppComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  declarations:[]
})
export class AppRoutingModule { }
