<div *ngIf="total; else loading" id="composant" class="col-xs-12 col-md-6">
    <table id="tableau" class="table table-striped">
        <thead>
            <tr id="libelle">
                <th scope="col">#</th>
                <th scope="col">Label</th>
                <th scope="col">Number</th>
                <th scope="col">Ratio</th>
            </tr>


            <tr class="lignesTableau" *ngFor="let record of label; let i = index" (click)="addNewValue(ccomponent.innerText)">
                <th scope="row">{{ i+1 }}</th>
                <td #ccomponent>{{ record.primary_type }}</td>
                <td>{{ record.count }}</td>
                <td>{{ record.count / total[0].count *100 | number : "1.0-1"}} % </td>
            </tr>
        </thead>
    </table>
</div>
<ng-template #loading>
    Loading...
</ng-template>
