import { Component, OnInit, Injectable } from '@angular/core';
import { Footer } from '../services/footer';
import { FooterService } from '../services/footer.service';


@Component({
  selector: 'app-ml-page',
  templateUrl: './ml-page.component.html',
  styleUrls: ['./ml-page.component.scss']
})
export class MlPageComponent implements OnInit {

  public tab : Array<Footer> = [];

  constructor(private dataService: FooterService) { }

  ngOnInit(): void {
    //Récuperer les données du service au chargement
    this.dataService.getData().subscribe(res => {this.tab = res;
    });


    //Puis les injecter dans la page


  }

}
