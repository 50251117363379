import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';

import { ParkingService } from '../services/parking.service';

import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-parking',
  templateUrl: './parking.component.html',
  styleUrls: ['./parking.component.scss']
})
export class ParkingComponent implements OnInit {
  carte: any[];

  public url: string = 'error';

  cheminImg: string = "../assets/";

  filter = L.control.layers();

  constructor(private http: HttpClient,
    private serviceParking: ParkingService,
    private router: Router,
    private location: Location
  ) {
    this.router.events.subscribe((val) => {
      this.url = this.location.path();
    });
   }

  // Fonction d'initialisation du composant.
  ngOnInit() {
    // Déclaration de la carte avec les coordonnées du centre et le niveau de zoom.
    const myparkingmap = L.map('frugalmap').setView([43.604652, 1.444209], 12
    );

    //  map
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: 'Frugal Map'
    }).addTo(myparkingmap);

    //icon de la map
    const myIcon = L.icon({
      iconUrl: `${this.cheminImg}parking.png`,
        iconRetinaUrl: `${this.cheminImg}parking.png`,
        iconSize: [25, 25],
        iconAnchor: [12, 41],
        popupAnchor: [1, -41],
        // shadowUrl: `${this.cheminImg}marker-shadow.png`,
        // shadowSize: [41, 41]
        //iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/images/marker-icon.png'
    });

    //  recupere le json
    this.serviceParking.getCP().subscribe((data: any) => {
      //console.log(data);
      const regex = new RegExp('parking', 'gi');
      data.forEach((c: any) => {
         let lng = c.geometry.coordinates[0];
         let lat = c.geometry.coordinates[1];
         let marker = L.marker([lat, lng], { icon: myIcon })
            .bindPopup(`<p style="text-align: center">${c.fields.adresse}</p>`);
        if (regex.test(c.fields.categorie)) {
          //console.log(c.fields.categorie);
          //this.carte.push(c);
          marker.addTo(myparkingmap);
        }
          //console.log(data.records);
      });

    });

  }
}
