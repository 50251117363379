import { Component, OnInit, Input } from "@angular/core";
import * as L from "leaflet";
import { icon, latLng, marker, Map } from 'leaflet';
import "leaflet.markercluster";
import { ChicagoService } from "../services/chicago.service";

@Component({
  selector: 'app-chicago-map',
  templateUrl: './chicago-map.component.html',
  styleUrls: ['./chicago-map.component.scss']
})
export class ChicagoMapComponent implements OnInit {
  annee=2021;
  crime="BATTERY";
  @Input() year :number ;
  @Input() item: any;



  lastLayer: L.Marker<any>;

  title(title: any) {
    throw new Error('Method not implemented.');
  }

  constructor(private markerService: ChicagoService) {
  }

  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterData: L.Marker[] = [];
  map: Map;
  options: L.MapOptions;


  onMapReady(map: Map) {
    this.map = map;
    this.addLayersToMap();
  }




  ngOnInit() {
    this.initializeOptions();
    this.markerClusterGroup = L.markerClusterGroup({ removeOutsideVisibleBounds: true });
    this.createMarker();


  }

  ngOnChanges(changes) {
    if (this.item){
    this.crime=this.item;
    }
    this.annee=this.year;
    this.createMarker();
  }





  private getDefaultIcon() {
    return icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/images/marker-icon.png'
    });
  }

  private createMarker() {
    if ((this.annee) && (this.crime!="")){
    const mapIcon = this.getDefaultIcon();
    this.markerClusterGroup.clearLayers();
    this.markerService.getCoordonnees(this.annee, this.crime).subscribe((data: any) => {
      for (let point in data) {
        if (Object.keys(data[point]).length > 0) {
          let coor = L.latLng({ lat: data[point].location.latitude, lng: data[point].location.longitude });
          //console.log(coor);
          this.lastLayer = marker(coor).setIcon(mapIcon);
          this.markerClusterGroup.addLayer(this.lastLayer)
        }
      }
    });
  }
}

  private addLayersToMap() {
    this.markerClusterGroup.addTo(this.map);
  }

  private initializeOptions() {
    this.options = {
      center: L.latLng(41.8781136, -87.6297982),
      zoom: 13,
      layers: [
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          minZoom: 9,
          attribution: 'Map Chicago'
        })
      ]
    }
  }
}
