import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Footer } from './footer';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  private localUrl : string = '../assets/mentions_legales.json';

  constructor(private http: HttpClient) {}

  //Fonction pour récuperer les données et en formatant grâce à l'interface
  public getData(): Observable<Footer[]>{
      return this.http.get<Footer[]>(this.localUrl);
  }
}
