import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-barre-nav',
  templateUrl: './c-barre-nav.component.html',
  styleUrls: ['./c-barre-nav.component.scss']
})
export class CBarreNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
