import { Component, OnInit } from '@angular/core';
import * as librairieMap from 'leaflet';
import { DataService } from '../services/data.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-c-map',
  templateUrl: './c-map.component.html',
  styleUrls: ['./c-map.component.scss']
})
export class CMapComponent implements OnInit {

  constructor(private dataCarteService: DataService, private titleService:Title) {
    this.titleService.setTitle("Carte Art");
   }

   ngOnInit() {
    //chargement pour la carte
    const myfrugalmap = librairieMap.map('frugalmap').setView([48.91936, 2.45169], 11.5);

    librairieMap.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiY2ZyYWlzc2VpeCIsImEiOiJja2w4MmY5MmwwODc4Mm9sYnFsejVwYXgyIn0.h3Xm8VWp9mkup2mvKPHrDQ'
    }).addTo(myfrugalmap);


    const myIcon = librairieMap.icon({
      iconUrl: 'https://i.pinimg.com/originals/e5/f6/ff/e5f6ff965e1bb0488d1e6c975dd326e2.png',
      iconSize: [40, 45], // size of the icon
    });

    // récupération des données et géolocalisation
    this.dataCarteService.getData().subscribe((data: any) => {

      data.forEach(donnee => {
        librairieMap.marker([donnee.geometry.coordinates[1], donnee.geometry.coordinates[0]],
          { icon: myIcon }).addTo(myfrugalmap)
          .bindPopup(`<b>Artiste:</b> <i>${donnee.properties.artiste}</i>
          <br>
          <br><b>Nom de l'oeuvre:</b> <i>${donnee.properties.titre}</i></br>
          <br>
          <b><center><img src="${donnee.properties.photo}" width="200" height="150"></center>`)
      });
    });
  }
}
